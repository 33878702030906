export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

// fetch products

export function fetchProducts(dispatch) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  fetch("http://mymotorluperde.com/app/GetProductData", requestOptions)
    .then((response) => response.json())
    .then((results) => {
      dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: results });
    });
}
